import React, { useState, useEffect } from "react";
import { motion } from 'framer-motion';
import { Calendar } from "lucide-react";
import { PortfolioData } from "./PortfolioData";
export default function Education (){


       
    const [data, setData] = useState<PortfolioData | null >(null);

    useEffect(() => {
        fetch('/data.json')
            .then(response => response.json())
            .then((jsonData: PortfolioData) => setData(jsonData))
            .catch(error => console.error('Error fetching data:', error));
    }, []);
    return (
        <>
        {data && (
          <>
            <p className="font-semibold">{data.education.degree}</p>
            <div className="flex flex-wrap gap-2 mb-4 mt-2">
              <p className="bg-red-900 w-max bg-opacity-50 text-white px-2 py-1 rounded-full text-xs">{data.education.university}</p>
              <p className="bg-red-900 w-max bg-opacity-50 text-white px-2 py-1 rounded-full text-xs">{data.education.gpa}</p>
              <p className="bg-red-900 w-max bg-opacity-50 text-white px-2 py-1 rounded-full text-xs"> Honors </p>
            </div>
            
            <p className="flex items-center text-sm text-gray-400 mb-4">
              <Calendar size={14} className="mr-2" />
              {data.education.duration}</p>
            <p className="mt-2 font-semibold">Relevant Courses:</p>
            <ul className="list-disc list-inside">
              {data.education.courses.map((course, index) => (
                <motion.li 
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="text-red-400"
                >
                  <motion.span
                    animate={{ scale: [1, 1.1, 1] }}
                    transition={{ duration: 1, repeat: Infinity }}
                  >
                    {course}
                  </motion.span>
                </motion.li>
              ))}
            </ul>
          </>
        )}
        </>
    )
}