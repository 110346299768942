import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Calendar } from "lucide-react";
import { PortfolioData } from "./PortfolioData";

export default function Honors() {
  const [data, setData] = useState<PortfolioData | null>(null);

  useEffect(() => {
    fetch("/data.json")
      .then((response) => response.json())
      .then((jsonData: PortfolioData) => setData(jsonData))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <>
      {data &&
        data.honors.map((honor, index) => (
          <motion.div
            key={index}
            className="mb-4 bg-gray-800 bg-opacity-50 p-4 rounded-lg flex flex-col md:flex-row items-start md:items-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            {/* Club Logo */}
            {honor.logo && (
              <img
                src={honor.logo}
                alt={`${honor.title} Logo`}
                className="w-12 h-12 md:w-16 md:h-16 mr-4 rounded-full"
              />
            )}
            {/* Club Details */}
            <div>
              <h3 className="font-semibold text-xl">{honor.title}</h3>
              <div className="flex flex-wrap mt-2 items-center justify-between">
                <p
                  className="bg-red-900 bg-opacity-50 text-white px-2 py-1 rounded-full text-xs mb-5"
                  style={{ lineHeight: "1", padding: "4px 8px" }} // Adjusted line-height and padding
                >
                  {honor.position}
                </p>
                <p className="flex items-center text-sm text-gray-400">
                  <Calendar size={14} className="mr-2" />
                  {honor.date}
                </p>
              </div>
              <p className="italic text-xs mb-2">What I do...</p>
              <p className="text-gray-300">{honor.description}</p>
            </div>
          </motion.div>
        ))}
    </>
  );
}
