import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Send } from 'lucide-react';

export default function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // console.log('Form submission started.');

    const payload = { name, email, message };
    // // console.log('Payload being sent to API:', payload);  // Console log the payload being sent

    try {
        const response = await fetch('https://ly3z8897j2.execute-api.us-east-1.amazonaws.com/dev', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        // console.log('API Response:', response);

        if (response.ok) {
            // Handle successful response
            // const responseBody = await response.json(); // Parse the JSON response body
            // console.log('Success response body:', responseBody); // Log the successful response body
            setSubmitted(true);
            setName('');
            setEmail('');
            setMessage('');
            // console.log('Form submitted successfully!');
        } else {
            // Handle failed response
            const errorResponse = await response.json();  // To capture the response body in case of an error
            // console.error('Error submitting the form:', errorResponse);
            alert(`Error: ${errorResponse.message || 'Something went wrong'}`); // Show a user-friendly error message
        }
    } catch (error) {
        // console.error('Network error:', error);
        alert('Network error: Unable to submit the form.');
    }
};

  return (
    <div className="min-h-screen bg-gray-900 text-white relative flex items-center justify-center">
      <motion.div 
        className="bg-gray-800 bg-opacity-50 p-6 rounded-lg max-w-md w-full"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-2xl font-semibold mb-4 text-center">Send a Message</h2>
        {submitted ? (
          <motion.p 
            className="text-green-400 text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Thank you for your message! I'll get back to you soon.
          </motion.p>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="name" className="block mb-1">Name</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className="w-full bg-gray-700 bg-opacity-50 rounded px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-red-500"
              />
            </div>
            <div>
              <label htmlFor="email" className="block mb-1">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full bg-gray-700 bg-opacity-50 rounded px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-red-500"
              />
            </div>
            <div>
              <label htmlFor="message" className="block mb-1">Message</label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
                rows={4}
                className="w-full bg-gray-700 bg-opacity-50 rounded px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-red-500"
              />
            </div>
            <button
              type="submit"
              className="flex items-center justify-center w-full bg-red-600 hover:bg-red-500 text-white py-2 px-4 rounded mt-4"
            >
              <Send className="mr-2" />
              Send Message
            </button>
          </form>
        )}
      </motion.div>
    </div>
  );
}
