import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Calendar, Github } from "lucide-react";
import { PortfolioData } from "./PortfolioData";

export default function Projects() {
  const [data, setData] = useState<PortfolioData | null>(null);

  useEffect(() => {
    fetch("/data.json")
      .then((response) => response.json())
      .then((jsonData: PortfolioData) => setData(jsonData))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <>
      {/* Main Projects Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {data &&
          data.projects.map((project, index) => (
            <motion.div
              key={index}
              className="bg-gray-800 bg-opacity-100 rounded-lg overflow-hidden shadow-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div className="p-6">
                <div className="flex justify-between items-start mb-2">
                  <h3 className="font-semibold text-xl">{project.name}</h3>
                  <a
                    href={project.github}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-red-400 hover:text-red-300"
                  >
                    <Github size={24} />
                  </a>
                </div>
                <div className="flex items-center text-sm text-gray-400 mb-4">
                  <Calendar size={14} className="mr-2" />
                  {project.date}
                </div>
                <div className="flex flex-wrap gap-2 mb-4">
                  {project.technologies.map((tech, i) => (
                    <span
                      key={i}
                      className="bg-red-900 bg-opacity-50 text-white px-2 py-1 rounded-full text-xs"
                    >
                      {tech}
                    </span>
                  ))}
                </div>
                <p className="italic text-xs mb-3">What I did...</p>
                <ul className="list-disc list-inside text-gray-300">
                  {project.description.map((desc, i) => (
                    <li key={i} className="mb-1 mt-2">
                      {desc}
                    </li>
                  ))}
                </ul>
              </div>
            </motion.div>
          ))}
      </div>

      {/* Small Projects Section */}
      {data && data.smallProjects && data.smallProjects.length > 0 && (
        <>
          <h3 className="text-2xl font-bold mt-8 mb-4">Other Projects</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {data.smallProjects.map((project, index) => (
              <motion.div
                key={index}
                className="bg-gray-800 bg-opacity-50 p-4 rounded-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <div className="flex justify-between items-start mb-2">
                  <h3 className="font-semibold text-xl">{project.name}</h3>
                  <a
                    href={project.github}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-red-400 hover:text-red-300"
                  >
                    <Github size={24} />
                  </a>
                </div>
                <div className="flex flex-wrap gap-2 mb-4">
                  {project.technologies.map((tech, i) => (
                    <span
                      key={i}
                      className="bg-red-900 bg-opacity-50 text-white px-2 py-1 rounded-full text-xs"
                    >
                      {tech}
                    </span>
                  ))}
                </div>
                <p className="italic text-xs mb-2">What this is...</p>
                <p className="text-gray-300">{project.description}</p>
              </motion.div>
            ))}
          </div>
        </>
      )}
    </>
  );
}
