import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Calendar, SquareArrowOutUpRight } from "lucide-react";
import { PortfolioData } from "./PortfolioData";

export default function Certifications() {
    const [data, setData] = useState<PortfolioData | null>(null);

    useEffect(() => {
        fetch("/data.json")
            .then((response) => response.json())
            .then((jsonData: PortfolioData) => setData(jsonData))
            .catch((error) => console.error("Error fetching data:", error));
    }, []);

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {data &&
                data.certifications.map((cert, index) => (
                    <motion.div
                        key={index}
                        className="relative bg-gray-800 bg-opacity-50 p-6 rounded-lg flex flex-col justify-between h-full"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: index * 0.1 }}
                    >
                        <div className="flex items-center mb-4">
                            {/* Render image to the left */}
                            {cert.image && (
                                <img
                                    src={`${cert.image}`}
                                    alt={`${cert.name} certificate`}
                                    className="w-16 h-16 object-cover rounded-md mr-4"
                                />
                            )}
                            <h3 className="font-semibold text-lg">{cert.name}</h3>
                        </div>
                        <div className="flex flex-wrap justify-between mb-4">
                            <p className="bg-red-900 w-max h-max bg-opacity-50 text-white px-2 py-1 rounded-full text-xs">
                                {cert.issuer}
                            </p>
                            <p className="flex items-center text-sm text-gray-400">
                                <Calendar size={14} className="mr-2" />
                                {cert.date}
                            </p>
                        </div>
                        {/* Icon positioned at the right lower bottom */}
                        <a
                            href={cert.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="absolute right-4 bottom-4 text-red-400 hover:text-red-300"
                        >
                            <SquareArrowOutUpRight size={24} />
                        </a>
                    </motion.div>
                ))}
        </div>
    );
}
