import React, { useState, useEffect } from "react";
import { motion } from 'framer-motion';
import { Code } from "lucide-react";
import { PortfolioData } from "./PortfolioData";

export default function Skills(){
        
    const [data, setData] = useState<PortfolioData | null >(null);

    useEffect(() => {
        fetch('/data.json')
            .then(response => response.json())
            .then((jsonData: PortfolioData) => setData(jsonData))
            .catch(error => console.error('Error fetching data:', error));
    }, []);
    const skillIcons: { [key: string]: React.ReactNode } = {
        Java: <i className="devicon-java-plain colored"></i>,
        "C#": <i className="devicon-csharp-plain colored"></i>,
        Python: <i className="devicon-python-plain colored"></i>,
        JavaScript: <i className="devicon-javascript-plain colored"></i>,
        TypeScript: <i className="devicon-typescript-plain colored"></i>,
        SQL: <i className="devicon-mysql-plain colored"></i>,
        PHP: <i className="devicon-php-plain colored"></i>,
        Dart: <i className="devicon-dart-plain colored"></i>,
        HTML: <i className="devicon-html5-plain colored"></i>,
        CSS: <i className="devicon-css3-plain colored"></i>,
        React: <i className="devicon-react-original colored"></i>,
        "ASP.NET": <i className="devicon-dot-net-plain colored"></i>,
        Angular: <i className="devicon-angularjs-plain colored"></i>,
        Spring: <i className="devicon-spring-plain colored"></i>,
        "Node.js": <i className="devicon-nodejs-plain colored"></i>,
        Flutter: <i className="devicon-flutter-plain colored"></i>,
        Git: <i className="devicon-git-plain colored"></i>,
        Docker: <i className="devicon-docker-plain colored"></i>,
        AWS: <i className="devicon-amazonwebservices-original colored"></i>,
        Linux: <i className="devicon-linux-plain colored"></i>,
      };

    return (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {data && Object.entries(data.skills).map(([category, skills], index) => (
          <motion.div 
            key={category} 
            className="bg-gray-800 bg-opacity-50 p-4 rounded-lg"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <h3 className="font-semibold capitalize mb-2">{category}</h3>
            <div className="flex flex-wrap gap-2">
              {skills.map((skill, i) => (
                <motion.div
                  key={i}
                  className="flex items-center bg-gray-700 bg-opacity-50 px-2 py-1 rounded-full text-sm"
                  whileHover={{ scale: 1.05 }}
                >
                  <span className="mr-1 text-xl">{skillIcons[skill] || <Code size={16} />}</span>
                  <span>{skill}</span>
                </motion.div>
              ))}

            </div>
          </motion.div>
        ))}
      </div>
    );
}