import React, { useState, useEffect } from "react";
import { motion } from 'framer-motion';
import { Calendar } from "lucide-react";
import { PortfolioData } from "./PortfolioData";

export default function Experience (){


       
    const [data, setData] = useState<PortfolioData | null >(null);

    useEffect(() => {
        fetch('/data.json')
            .then(response => response.json())
            .then((jsonData: PortfolioData) => setData(jsonData))
            .catch(error => console.error('Error fetching data:', error));
    }, []);
    return (
        <>
        {data && data.experience.map((exp, index) => (
            <motion.div 
              key={index} 
              className="mb-6 bg-gray-800 bg-opacity-50 p-4 rounded-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <h3 className="font-semibold text-xl">{exp.title}</h3>
              
              <div className="flex flex-wrap justify-between mb-4">
              <p className="bg-red-900 mt-2 w-max h-max bg-opacity-50 text-white px-2 py-1 rounded-full text-xs">{exp.company}</p>
              
              <p className="flex items-center text-sm text-gray-400 mb-4">
                <Calendar size={14} className="mr-2" />
                {exp.duration}
              </p>
              </div>
              <p className="italic text-xs"> What I worked on... </p>
              <p className="font-semibold mt-2 text-red-400">{exp.project}</p>
              <br/>
              <p className="italic text-xs "> What I did...</p>
              <ul className="list-disc list-inside mt-2">
                {exp.description.map((desc, i) => (
                  
                  <li key={i} className="text-gray-300 mt-2">{desc}</li>
                  
                ))}
              </ul>
            </motion.div>
          ))}
        </>
    )
}